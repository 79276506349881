import React from 'react';

// Assuming the utility function getTimeAgo is either defined in this file or imported

const GettTimeAgo = ({ item }) => {
    // Use the utility function to get the relative time string
    function getTimeAgos(createDate) {
        const itemDate = new Date(createDate);
        const now = new Date();
        const diffInMilliseconds = now - itemDate;
        const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

        if (diffInMinutes < 60) {
            return `${diffInMinutes} mins ago`;
        } else if (diffInMinutes < 1440) { // Less than 24 hours
            const diffInHours = Math.floor(diffInMinutes / 60);
            return `${diffInHours} hours ago`;
        } else {
            const diffInDays = Math.floor(diffInMinutes / (60 * 24));
            return `${diffInDays} days ago`;
        }
    }
    const timeAgo = getTimeAgos(item.createDate);

    return (
        <div>
            <p>{timeAgo}</p>
        </div>
    );
};

export default GettTimeAgo;
