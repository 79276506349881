import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import Layout from './components/layout/Layout';
import Header from "./components/layout/header";
import Footer from "./components/layout/footer";
import BackToTop from "./components/elements/backToTop";
import './custom.css'




export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
        <Layout>
            <Header></Header>
            <Routes>
              
          {AppRoutes.map((route, index) => {
            const { element, ...rest } = route;
            return <Route key={index} {...rest} element={element} />;
          })}
            </Routes>
            <Footer />
            <BackToTop />
      </Layout>
    );
  }
}
