import React, { Component, useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import Menu from "./menu";



const Header = () => {
    // State to hold the screen size
    //const { addClass, openSearch } = this.props;
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        // Function to update the state based on window size
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Clean up: Remove event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Function to determine the class name
    const getClassName = () => {
        return isMobile ? 'col-xs-12' : 'col-md-3 col-xs-6';
    };

    return (
        <header className="blur-background main-header header-style-3 font-heading">
            <div className="header-top">
                <div className="container border-topline">
                    <div className={`row ${isMobile ? '' : 'pt-20 pb-20'}`}>
                        <div className={getClassName()}>
                            <Link to="/">
                                <img
                                    className="logo"
                                    src="/assets/imgs/theme/logo_line.png"
                                    alt=""
                                />
                            </Link>
                        </div>
                        <div className="col-md-9 col-xs-6 text-right header-top-right ">
                            <ul className="list-inline nav-topbar d-none d-md-inline">
                                <li className="list-inline-item menu-item-has-children">
                                    <a href="/#">Hesaplama</a>

                                    <ul className="sub-menu font-small">
                                        <li className="menu-item">
                                            <a className="text-dark" href="/#">Hesap Makinesi</a>

                                        </li>
                                        <li className="menu-item">
                                            <a className="text-dark" href="/#">Is? Haritas?</a>

                                        </li>
                                        <li className="menu-item">
                                            <a className="text-dark" href="/#">Fiyat Endeskleri</a>

                                        </li>

                                    </ul>
                                </li>

                            </ul>

                        </div>
                    </div>
                </div>
            </div>
            <Menu addClass={null} />
        </header>
    );
};

export default Header;






