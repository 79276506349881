import { useState, useEffect } from 'react';
import axios from 'axios';

const useCategory = (categoryID) => {
    const [categoryName, setCategoryName] = useState("Loading...");
    const [categoryRName, setCategoryRName] = useState("Loading...");

    useEffect(() => {
        axios.get(`https://api.kriptosut.com/api/v1/haberler/kategori/getir/${categoryID}`)
            .then(response => {
                setCategoryName(response.data.name);
                setCategoryRName(response.data.reWrite);
            })
            .catch(error => {
                console.error('Error fetching category data: ', error);
                setCategoryName("Not Found");
            });
    }, [categoryID]);

    return { categoryName, categoryRName };
}
export default useCategory;


