import { Counter } from "./components/Counter";
import { FetchData } from "./components/FetchData";
import Home from "./components/Home";
import Category from "./pages/category-masonry";
import Single from "./pages/single";

const AppRoutes = [
  {
    index:true,
    element: <Home />
  },
  {
      path: '/news/:rewrite',
      element: <Category />
  },
  {
      path: '/news/:rewrite/:postname',
      element: <Single />
  }
];

export default AppRoutes;
