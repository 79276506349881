import React, { useState } from "react";
import Bottom from "./bottom";
import Search from "./../elements/search";
import Footer from "./footer";
import Header from "./headerNew";
import { Link } from 'react-router-dom';
import Menu from "./menu";
import BackToTop from "./../elements/backToTop";
import { Helmet } from 'react-helmet';


const Layout = ({ children }) => {
   

    const openSearch = () => {
        document.body.classList.toggle("open-search-form");
    };

    return (
        <>
            <Helmet>
                <title>Crypto Super Shoot | Kripto Haber Dünyası</title>
                <meta property="og:title" content="My page title" key="title" />
                <link href="https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@0,400;0,700;1,400&family=Noto+Sans+JP:wght@400;500;700;900&display=swap" rel="stylesheet" />
                <link rel="stylesheet" href="./../assets/css/style.css" />
                <link rel="stylesheet" href="./../assets/css/widgets.css" />
                <link rel="stylesheet" href="./../assets/css/responsive.css" />

               
            </Helmet>
         
            
           

            {children}

           
            
           
        </>
    );
};

export default Layout;
