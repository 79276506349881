import React, { useEffect, useState } from 'react';
import axios from 'axios'; // make sure to install axios or use fetch API
import { Link, useParams } from 'react-router-dom';


import Layout from "./../components/layout/Layout";
import BinanceWidget from './../components/slider/BinanceWidget';
import useCategory from './../components/ekstra/useCategory';
import GettTimeAgo from '../components/ekstra/GettTimeAgo';

function GetCategory({ categoryID }) {
    const [categoryName, setCategoryName] = useState("Loading...");

    useEffect(() => {
        axios.get(`https://api.kriptosut.com/api/v1/haberler/kategori-id/${categoryID}`)
            .then(response => {
                // Assuming 'response.data' has a 'name' property
                setCategoryName(response.data.name);
            })
            .catch(error => {
                console.error('Error fetching category data: ', error);
                setCategoryName("Not Found");
            });
    }, [categoryID]); // Dependency array, ensures the effect runs only when 'categoryID' changes

    return <span>{categoryName}</span>;
}


function CaregoryMasonary() {
    
    const params = useParams();
    const rewrite = params.rewrite;
    const [data, setData] = useState([]);
    const [category, setCategory] = useState([]);
    const { categoryName, categoryRName } = useCategory(rewrite);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://api.kriptosut.com/api/v1/haberler/filter/${rewrite}`);
                setData(response.data); // Set data from the first API
                console.log(response.data); 
                const cat = await axios.get(`https://api.kriptosut.com/api/v1/haberler/kategori/getir/${rewrite}`);
                setCategory(cat.data); // Set category from the second API
               
            } catch (error) {
                console.error('Error fetching data: ', error);
            }
        };

        fetchData();
    }, [rewrite]); // Re-fetch data when rewrite changes

    return (
        <>
            <Layout>
                <main className="blur-background">
                    {/* <!--archive header--> */}
                    <div className="blur-background archive-header pt-50 text-center pb-50">
                        <div className="container">
                            <h2 className="font-weight-900">{category.description}</h2>
                            <div className="breadcrumb">
                                <Link href="/">
                                    <a rel="nofollow">Anasayfa</a>
                                </Link>
                                <span> {categoryName}</span> 
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="container">
                            <div className="hot-tags pt-30 pb-30 font-small align-self-center">
                                <div className="widget-header-3">
                                    <div className="row align-self-center">
                                        <div className="col-md-12 align-self-center">
                                            <BinanceWidget></BinanceWidget>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!--Loop Masonry--> */}

                        <div className="grid row mb-30">
                            <div className="grid-sizer"></div>
                            {data.map((item, i) => (
                                <article
                                    className="col-lg-4 col-md-6 mb-30 wow fadeInUp animated"
                                    data-wow-delay="0.2s"
                                >
                                    <div className="post-card-1 border-radius-10 hover-up">
                                        <div
                                            className="post-thumb thumb-overlay img-hover-slide position-relative"
                                            style={{
                                                backgroundImage: `url(${item.coverPicture})`
                                            }}
                                        >
                                            <Link key={item.id} to={`news/${categoryRName}/${item.rewrite}`} className="img-link"></Link>
                                           
                                            <span className="top-right-icon bg-success">
                                                <i className="elegant-icon icon_camera_alt"></i>
                                            </span>

                                        </div>
                                        <div className="post-content p-30">
                                            <div className="entry-meta meta-0 font-small mb-10">
                                                <GetCategory categoryID={item.pK_CategoryID} />
                                            </div>
                                            <div className="d-flex post-card-content">
                                                <h5 className="post-title mb-20 font-weight-900">
                                                    <Link to={`news/${categoryRName}/${item.rewrite}`}>
                                                        <a>
                                                            {item.title}
                                                        </a>
                                                        <p>
                                                            Devamını oku..
                                                        </p>
                                                    </Link>
                                                </h5>
                                                <div className="entry-meta meta-1 float-right font-x-small text-uppercase">


                                                    <span className="">
                                                        <GettTimeAgo item={item} />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                                //<article className="grid-item col pb-50 wow fadeIn animated">
                                //    <div className="post-card-1 border-radius-10 hover-up">
                                //        <div
                                //            className="post-thumb thumb-overlay img-hover-slide position-relative"
                                //            style={{
                                //                backgroundImage: `url(${item.coverPicture})`
                                //            }}
                                //        >
                                //            <a href={`news/${categoryRName}/${item.rewrite}`}>
                                //                <a className="img-link"></a>
                                //            </a>
                                //            <span className="top-right-icon bg-success">
                                //                <i className="elegant-icon icon_camera_alt"></i>
                                //            </span>
                                           
                                //        </div>
                                //        <div className="post-content p-30">
                                //            <div className="entry-meta meta-0 font-small mb-10">
                                //                <a href={`/news/${categoryRName}`}>
                                //                    <span className="post-cat text-info">{categoryName}</span>
                                //                </a>
                                //            </div>
                                //            <div className="d-flex post-card-content">
                                //                <h5 className="post-title mb-20 font-weight-900">
                                //                    <a href={`news/${categoryRName}/${item.rewrite}`}>
                                //                        <b>{item.title}</b>
                                //                    </a>
                                //                </h5>
                                //                <p>{item.canoncial}</p>
                                //                <div className="entry-meta meta-1 float-left font-x-small text-uppercase">
                                //                    <span className="post-on">{item.createDate}</span>
                                                 
                                //                </div>
                                //            </div>
                                //        </div>
                                //    </div>
                                //</article>
                            ))}
                        </div>
                        <div className="row">
                            
                        </div>
                    </div>
                </main>
            </Layout>
        </>
    );
}
export default CaregoryMasonary;
