import React, { useEffect, useState } from 'react';
import axios from 'axios'; // make sure to install axios or use fetch API
import { Link, useParams } from 'react-router-dom';





const Footer = ({ removeClass }) => {
    const [data, setData] = useState([]);
    const [bilgi, setBilgi] = useState([]);
    const [gen, setGen] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const sayfalar = await axios.get(`https://api.kriptosut.com/api/v1/gen/sayfalar/listele/9`);
                
                setData(sayfalar.data);
                const bilgilendirme = await axios.get(`https://api.kriptosut.com/api/v1/gen/sayfalar/listele/10`);
                setBilgi(bilgilendirme.data); // Set data from the first API

                const gesite = await axios.get(`https://api.kriptosut.com/api/v1/gen/site/1`);
                setGen(gesite.data); // Set data from the first API
                console.log(gesite.data);
                //const formattedDate = format(new Date(data.createDate), 'MMMM dd, yyyy');



            } catch (error) {
                console.error('Error fetching data: ', error);
            }
        };

        fetchData();
    }, []);


    return (
        <>
            <footer className="footer_backg pt-50 pb-20">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <div className="sidebar-widget wow fadeInUp animated mb-30">
                                <div className="widget-header-2 position-relative mb-30">
                                    <h5 className="mt-5 mb-30">Hakkında</h5>
                                </div>
                                <div className="textwidget">
                                    <p>
                                        {gen.description}
                                    </p>
                                    <p>
                                        <strong className="color-black">
                                            Adres
                                        </strong>
                                        <br />
                                        {gen.address}
                                    </p>
                                    <p>
                                        <strong className="color-black">
                                            Follow me
                                        </strong>
                                    </p>
                                    <br />
                                    <ul className="header-social-network d-inline-block list-inline color-white mb-20">
                                        <li className="list-inline-item">
                                            <a
                                                className="fb"
                                                target="_blank"
                                                title="Facebook"
                                            >
                                                <i className="elegant-icon social_facebook"></i>
                                            </a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a
                                                className="tw"
                                                target="_blank"
                                                title="Tweet now"
                                            >
                                                <i className="elegant-icon social_twitter"></i>
                                            </a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a
                                                className="pt"
                                                target="_blank"
                                                title="Pin it"
                                            >
                                                <i className="elegant-icon social_pinterest"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6">
                            <div
                                className="sidebar-widget widget_categories wow fadeInUp animated mb-30"
                                data-wow-delay="0.1s">
                                <div className="widget-header-2 position-relative mb-30">
                                    <h5 className="mt-5 mb-30">Sayfalar</h5>
                                </div>
                                <ul className="font-small">
                                    {data.map((item, i) => (
                                        <li key={i} className="cat-item cat-item-2">
                                            <Link className="text-ligter" to={`/sayfalar/${item.reWrite.toLowerCase()}`}>
                                                {item.title}
                                            </Link>
                                        </li>
                                    ))}
                                   
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6">
                            <div
                                className="sidebar-widget widget_categories wow fadeInUp animated mb-30"
                                data-wow-delay="0.1s"
                            >
                                <div className="widget-header-2 position-relative mb-30">
                                    <h5 className="mt-5 mb-30">Bilgilendirme</h5>
                                </div>
                                <ul className="font-small">

                                    {bilgi.map((item, i) => (
                                        <li key={i}  className="cat-item cat-item-2">
                                            <Link className="text-ligter"  to={`/sayfalar/${item.reWrite.toLowerCase()}`}>
                                                {item.title}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                       
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="sidebar-widget widget_newsletter wow fadeInUp animated mb-30"
                                data-wow-delay="0.3s"
                            >
                                <div className="widget-header-2 position-relative mb-30">
                                    <h5 className="mt-5 mb-30">Abone OL</h5>
                                </div>
                                <div className="newsletter">
                                    <p className="font-medium">
                                       En güncel kripto gelişmelerinden haberdar olmak için haberdar olun.
                                    </p>
                                    <form className="input-group form-subcriber mt-30 d-flex">
                                        <input
                                            type="email"
                                            className="form-control bg-white font-small"
                                            placeholder="Enter your email"
                                        />
                                        <button
                                            className="btn bg-primary text-white"
                                            type="submit"
                                        >
                                           Kayıt OL
                                        </button>
                                        <label className="mt-20">
                                            {" "}
                                            <input
                                                className="mr-5"
                                                name="name"
                                                type="checkbox"
                                                value="1"
                                                required=""
                                            />{" "}
                                            I agree to the{" "}
                                            <Link href="/#">
                                                <a href="/#" target="_blank">
                                                    terms &amp; conditions
                                                </a>
                                            </Link>{" "}
                                        </label>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-copy-right pt-30 mt-20 wow fadeInUp animated">
                        <p className="float-md-left font-small text-bluelight">
                            © 2020, Crpyto Super Shoot - Crypto Currency Network{" "}
                        </p>
                        <p className="float-md-right font-small text-bluelight">
                            Design by HOST All rights reserved
                        </p>
                    </div>
                </div>
            </footer>

            <div className="dark-mark" onClick={removeClass}></div>
        </>
    );
};

export default Footer;
