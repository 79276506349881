import MetisMenu from "metismenujs";
import { Link } from 'react-router-dom';
import { useEffect, useLayoutEffect, useState, useRef } from "react";

import PerfectScrollbar from 'react-perfect-scrollbar';

import axios from 'axios';
const NavMenu = ({ isToggled }) => {
    const [menuItems, setMenuItems] = useState([]);
    const [openSubmenuId, setOpenSubmenuId] = useState(null);

    const toggleSubmenu = (itemId) => {
        // Toggle between the item ID and null to show/hide the submenu
        setOpenSubmenuId(openSubmenuId === itemId ? null : itemId);
    };

    useEffect(() => {
        

        // Fetch menu items from API
        axios.get('https://api.kriptosut.com/api/v1/haberler/kategoriler/menu')
            .then(response => {
                setMenuItems(response.data);
            })
            .catch(error => {
                console.log(error);
            });

        // Cleanup
        new MetisMenu("#metismenu");
       
    }, []);

    return (
        <>
            <div className={isToggled ? "mobilemenu active" : "mobilemenu hide"}>
                <PerfectScrollbar>
                    <ul className="metismenu text-muted" id="metismenu">
                        <li className="menu-item">
                            <a href="/">Anasayfa</a>
                        </li>
                        
                        {menuItems.map((item) => (
                            <li key={item.id} className="menu-item">
                                <a href="#" onClick={(e) => { e.preventDefault(); toggleSubmenu(item.id); }} className={`${item.subCategory && item.subCategory.length > 0 ? "has-arrow" : ""}`} aria-expanded={openSubmenuId === item.pK_ID ? "true" : "false"}>
                                    <Link to={`/News/${item.rewrite}`}>{item.name}</Link>
                                </a>
                                {item.subCategory && item.subCategory.length > 0 && openSubmenuId === item.id && (
                                    <ul>
                                        {item.subCategory.map((subItem) => (
                                            <li key={subItem.id}>
                                                <Link to={`/News/${subItem.rewrite}`}>{subItem.name}</Link>
                                                {subItem.subCategory && subItem.subCategory.length > 0 && (
                                                    <ul className="sub-menu text-muted font-small">
                                                        {subItem.subCategory.map((subSubItem) => (
                                                            <li key={subSubItem.id}>
                                                                <Link to={`/News/${subSubItem.rewrite}`}>{subSubItem.name}</Link>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </li>
                        ))}
                    </ul>
                </PerfectScrollbar>
            </div>

        </>
    );
};

export default NavMenu;
