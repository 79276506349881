import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom'; // Import useParams
import axios from 'axios'; // make sure to install axios or use fetch API

import Slider from "react-slick";
//import data from "../../data/post.json";


const HomeSlider3 = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        axios.get('https://api.kriptosut.com/api/v1/gen/slider/1')
            .then(response => {
                //console.log(response.data);
                setData(response.data); // Assuming the API returns an object with an 'items' array
            })
            .catch(error => {
                console.error('Error fetching RSS feed: ', error);
            });
    }, []);
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoPlay: true,
        prevArrow: (
            <button type="button" className="slick-prev slick-arrow">
                <i className="elegant-icon arrow_left"></i>
            </button>
        )
        //slice(4, 6)
    };
    return (
        <div>
            <Slider {...settings} className="featured-slider-3-items">
                {data.map((item, i) => (
                    <div key={item.pK_ID} className="slider-single overflow-hidden border-radius-10">
                        <div className="post-thumb position-relative">
                            <div
                                className="thumb-overlay position-relative"
                                style={{
                                    backgroundImage: `url(${item.pictureURL})`
                                }}
                            >
                                <div className="post-content-overlay">
                                    <div className="container">
                                        <div className="entry-meta meta-0 font-small mb-20">
                                            {/*<a key={item.pK_I} href={`/${url || 'N/A'}`}>*/}
                                            {/*    <span className="post-cat text-info">*/}
                                            {/*        DETAY*/}
                                            {/*    </span>*/}
                                            {/*</a>*/}
                                        </div>
                                        <h1 className="post-title mb-20 font-weight-900 text-white">
                                            <a href={`/blog/${item.pK_ID}`}>
                                                {item.canoncial2}
                                            </a>
                                        </h1>
                                        <div className="entry-meta meta-1 font-small text-white mt-10 pr-5 pl-5">
                                            <span className="post-on">
                                                {item.canoncial3}
                                            </span>
                                            {/* Assuming 'views' data is available */}
                                            <span className="hit-count has-dot">
                                                {item.views || 'N/A'} Views
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default HomeSlider3;
