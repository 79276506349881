import React, { useEffect } from 'react';

const BinanceWidget = () => {
    useEffect(() => {
        // Create script element
        const script = document.createElement('script');
        script.src = "https://public.bnbstatic.com/unpkg/growth-widget/cryptoCurrencyWidget@0.0.9.min.js";
        script.async = true;

        // Append script to the body
        document.body.appendChild(script);

        // Remove script when component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, []); // Empty dependency array ensures this runs once on mount only

    return (
        <div>
            {/* Your other JSX */}

            {/* Binance Widget Div */}
            <div
                className="binance-widget-marquee"
                data-cmc-ids="1,1027,1839,5426,52,3408,5805,74,3890,7083"
                data-theme="dark"
                data-transparent="true"
                data-locale="en">
            </div>

            {/* Rest of your JSX */}
        </div>
    );
};

export default BinanceWidget;
