import React, { useState } from "react";
import { Link } from 'react-router-dom';
import Menu from "./menu";

function headerNew() {
    const addClass = () => {
        document.body.classList.add("canvas-opened");
    };

    const removeClass = () => {
        document.body.classList.remove("canvas-opened");
    };

    const openSearch = () => {
        document.body.classList.toggle("open-search-form");
    };

    return (
        <>
            <div className=" font-heading">
                <div className="header-top">
                    <div className="container">
                        <div className="row pt-20 pb-20">
                            <div className="col-md-3 col-xs-6">
                                <a href="/">
                                    <img
                                        className="logo"
                                        src="/assets/imgs/theme/logo.png"
                                        alt=""
                                    />
                                </a>
                            </div>
                            
                        </div>
                    </div>
                </div>
               
            </div>

        </>
    );
};

export default headerNew;
