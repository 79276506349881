import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'; // Import useParams
import axios from 'axios'; // make sure to install axios or use fetch API

import Layout from "./../components/layout/Layout";
import HomeSlider3 from "./../components/slider/HomeSlider3";
import PostCarousel1 from "./../components/slider/PostCarousel1";

import BinanceWidget from './slider/BinanceWidget';
import useCategory from './../components/ekstra/useCategory';
import GettTimeAgo from './ekstra/GettTimeAgo';
function GetCategory({ categoryID }) {
    const [categoryName, setCategoryName] = useState("Loading...");

    useEffect(() => {
        axios.get(`https://api.kriptosut.com/api/v1/haberler/kategori-id/${categoryID}`)
            .then(response => {
                // Assuming 'response.data' has a 'name' property
                setCategoryName(response.data.name);
            })
            .catch(error => {
                console.error('Error fetching category data: ', error);
                setCategoryName("Not Found");
            });
    }, [categoryID]); // Dependency array, ensures the effect runs only when 'categoryID' changes

    return <span>{categoryName}</span>;
}

function Home() {

    const [data, setData] = useState([]);
    const [althaber, setSlider] = useState([]);
    const [haber, setHaber] = useState([]);
    const { categoryName, categoryRName } = useCategory("bitcoin");
    useEffect(() => {
        axios.get('https://api.kriptosut.com/api/v1/gen/slider/1')
            .then(response => {
                setData(response.data); // Set 'data' with response from API
            })
            .catch(error => {
                console.error('Error fetching slider data: ', error);
            });

        axios.get('https://api.kriptosut.com/api/v1/gen/slider/2')
            .then(response => {
                console.log(response.data);
                setSlider(response.data); // Set 'data' with response from API
            })
            .catch(error => {
                console.error('Error fetching slider data: ', error);
            });

        axios.get('https://api.kriptosut.com/api/v1/haberler/haberler/hepsi')
            .then(response => {
                setHaber(response.data); // Set 'haber' with response from API

            })
            .catch(error => {
                console.error('Error fetching haber data: ', error);
            });
    }, []);

    // Check if data is not null or empty
  
    const isHaberAvailable = haber && haber.length > 0;
    const isSliderAlt = althaber && althaber.length > 0;


    return (
        <>
            <Layout>
                <main className="blur-background pb-30">
                    <div className="container pt-30">
                        <div className="featured-slider-3 position-relative">
                            <div className="slider-3-arrow-cover"></div>
                            <HomeSlider3 />
                        </div>
                    </div>
                    {/* <!-- End feature --> */}
                    <div className="container">
                        <div className="hot-tags pt-30 pb-30 font-small align-self-center">
                            <div className="widget-header-3">
                                <div className="row align-self-center">
                                    <div className="col-md-12 align-self-center">
                                        <BinanceWidget></BinanceWidget>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="hot-tags pt-30 pb-30 font-small align-self-center">
                            <div className="widget-header-3">
                                <div className="row align-self-center">
                                    <div className="col-md-4 align-self-center">
                                        <h5 className="widget-title">
                                            En Son Haberler
                                        </h5>
                                    </div>

                                    <div className="col-lg-8 mb-30">

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="loop-grid mb-30">
                            <div className="row">


                                {isHaberAvailable && haber.map((item, i) => (

                                    <article
                                        className="col-lg-4 col-md-6 mb-30 wow fadeInUp animated"
                                        data-wow-delay="0.2s"
                                    >
                                        <div className="post-card-1 border-radius-10 hover-up">
                                            <div
                                                className="post-thumb thumb-overlay img-hover-slide position-relative"
                                                style={{
                                                    backgroundImage: `url(${item.coverPicture})`
                                                }}
                                            >
                                                <Link key={item.id} to={`news/${categoryRName}/${item.rewrite}`} className="img-link"></Link>

                                                <span className="top-right-icon bg-success">
                                                    <i className="elegant-icon icon_camera_alt"></i>
                                                </span>

                                            </div>
                                            <div className="post-content p-30">
                                                <div className="entry-meta meta-0 font-small mb-10">
                                                    <GetCategory categoryID={item.pK_CategoryID} />
                                                </div>
                                                <div className="d-flex post-card-content">
                                                    <h5 className="post-title mb-20 font-weight-900">
                                                        
                                                            <a>
                                                                {item.title}
                                                            </a>
                                                       
                                                    </h5> <div className="color-white entry-meta mb-10">

                                                    <Link to={`news/${categoryRName}/${item.rewrite}`}>
                                                        Devamını oku..

                                                        </Link>
                                                    </div>
                                                    <div className="entry-meta meta-1 float-right font-x-small text-uppercase">
                                                       
                                                        
                                                        <span className="">
                                                            <GettTimeAgo item={item} />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                    //<article key={item.id}
                                    //    className="col-lg-4 col-md-6 mb-30 wow fadeInUp animated"
                                    //    data-wow-delay="0.2s"
                                    //>
                                    //    <div className="post-card-1 border-radius-10 hover-up">
                                    //        <div
                                    //            className="post-thumb thumb-overlay img-hover-slide position-relative"
                                    //            style={{
                                    //                backgroundImage: `url(${item.coverPicture})`
                                    //            }}
                                    //        >

                                    //            <Link key={item.id} to={`news/${categoryRName}/${item.rewrite}`} className="img-link"></Link>


                                    //        </div>
                                    //        <div className="post-content p-30">
                                    //            <div className="entry-meta meta-0 font-small mb-10">
                                    //                <a>
                                    //                    <span className="post-cat text-info">
                                    //                        <GetCategory categoryID={item.pK_CategoryID} />

                                    //                    </span>
                                    //                </a>
                                    //            </div>
                                    //            <div className="d-flex post-card-content">
                                    //                <h5 className="post-title mb-20 font-weight-900">
                                    //                    <a>
                                    //                        {item.title}
                                    //                    </a>
                                    //                    <p>
                                    //                        {item.canoncial }
                                    //                    </p>
                                    //                </h5>
                                    //                <div className="entry-meta meta-1 float-left font-x-small text-uppercase">
                                    //                    <span className="post-on">
                                    //                        {item.createDate}
                                    //                    </span>

                                    //                </div>
                                    //            </div>
                                    //        </div>
                                    //    </div>
                                    //</article>
                                ))}


                                {isSliderAlt && (
                                    <div className="col-lg-12 mb-30">
                                        <PostCarousel1 data={althaber} categoryRName={categoryRName} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                </main>
            </Layout >
        </>
    );
};

export default Home;

