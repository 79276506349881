import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom'; // Import useParams
import axios from 'axios'; // make sure to install axios or use fetch API
import Slider from "react-slick";
import useCategory from './../ekstra/useCategory';

const PostCarousel1 = (props) => {

    const [carouselData, setCarouselData] = useState(props.data);
    const { categoryName, categoryRName } = useCategory(props.categoryRName);
    

    //useEffect(() => {
    //    axios.get('https://api.kriptosut.com/api/v1/gen/slider/2')
    //        .then(response => {
    //            console.log(response.data);
    //            setData(response.data); // Assuming the API returns an object with an 'items' array
    //        })
    //        .catch(error => {
    //            console.error('Error fetching RSS feed: ', error);
    //        });
    //}, []);
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
    };
    return (

         <div>
            <div className="carausel-post-1 hover-up border-radius-10 overflow-hidden transition-normal position-relative wow fadeInUp animated">
                <div className="arrow-cover"></div>
                <Slider {...settings} className="slide-fade">
                    {carouselData.map((item, i) => (
                        <div style={{ backgroundImage: `url('${item.pictureURL}')`, backgroundSize: 'cover' }} className="position-relative post-thumb">
                            <div key={i}
                                className="thumb-overlay img-hover-slide position-relative"
                                style={{
                                    backgroundImage: `url("${item.pictureURL}")`
                                }}
                            >
                               
                                <a href={`/${item.pictureURL}`}
                                        className="img-link"
                                    ></a>
                              
                                <span className="top-left-icon bg-warning">
                                    <i className="elegant-icon icon_star_alt"></i>
                                </span>
                                <div className="post-content-overlay text-white ml-30 mr-30 pb-30">
                                    <div className="entry-meta meta-0 font-small mb-20">
                                        <span className="post-cat text-info">
                                            {item.canoncial1}
                                        </span>
                                    </div>
                                    <h3 className="post-title font-weight-900 mb-20">
                                        <a>
                                            {item.canoncial2}
                                        </a>
                                    </h3>
                                   
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
       
    );
};

export default PostCarousel1;

