import { Link } from 'react-router-dom';
import { useEffect, useLayoutEffect, useState,useRef } from "react";
import NavMenu from "./nav";
import axios from 'axios';

const Menu = ({ addClass }) => {
    const [scroll, setScroll] = useState(0);
    const [isToggled, setToggled] = useState(false);
    const [size, setSize] = useState(0);
    const [menuItems, setMenuItems] = useState([]);
    const [visibleSubmenu, setVisibleSubmenu] = useState(null);
    const [visibleSubSubmenu, setSubVisibleSubmenu] = useState(null);

    const toggleSubmenu = (itemId) => {
        // Toggle the visibility of the submenu
        setVisibleSubmenu(visibleSubmenu === itemId ? null : itemId);
    };

    const toggleSetSubmenu = (itemId) => {
        // Corrected to check visibleSubSubmenu for toggling subsubmenu
        setSubVisibleSubmenu(visibleSubSubmenu === itemId ? null : itemId);
    };
    const scrollRef = useRef(scroll); // useRef to track the scroll state

    // Function to handle the scroll event
    const handleMenuClick = (event, itemId) => {
        event.preventDefault(); // Prevent default link behavior
        event.stopPropagation(); // Stop the event from bubbling up
        toggleSubmenu(itemId);
    };

// Similar adjustment for subsubmenu items if needed

    const handleScroll = () => {
        const scrollCheck = window.scrollY > 100;
        if (scrollCheck !== scrollRef.current) {
            setScroll(scrollCheck);
            scrollRef.current = scrollCheck;
        }
    };
    const toggleTrueFalse = () => setToggled(!isToggled);
    useEffect(() => {
        document.addEventListener("scroll", () => {
            const scrollCheck = window.scrollY > 100;
            if (scrollCheck !== scroll) {
                setScroll(scrollCheck);
            }
        });
    });
    useEffect(() => {
        document.addEventListener("scroll", handleScroll);

        // Fetch menu items from API
        axios.get('https://api.kriptosut.com/api/v1/haberler/kategoriler/menu')
            .then(response => {
                console.log(response.data);
                setMenuItems(response.data);
            })
            .catch(error => {
                console.log(error);
            });

        // Cleanup
        return () => {
            document.removeEventListener("scroll", handleScroll);
        };
    }, []);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth]);
        }
        window.addEventListener("resize", updateSize);
    }, []);

    return (
        <>
            <div className={scroll ? "header-sticky sticky-bar" : "header-sticky"}>
                <div className="container align-self-center position-relative border-line">
                    <div className="main-nav float-left ">
                        <nav>
                            <ul className="main-menu d-none d-lg-inline font-small">
                                <li className="menu-item">
                                    <a href="/">Anasayfa</a>
                                </li>
                                {menuItems.map((item) => (
                                    <li key={item.id} className={`menu-item ${visibleSubmenu === item.id ? 'menu-item-has-children submenu-visible' : ''}`} onClick={(e) => { e.stopPropagation(); toggleSubmenu(item.id); }}>
                                        <Link to={`/News/${item.rewrite}`} onClick={(e) => item.subCategory.length > 0 && e.preventDefault()}>
                                            {item.name}
                                        </Link>
                                        {item.subCategory.length > 0 && (
                                            <ul className="sub-menu text-muted font-small">
                                                {item.subCategory.map((subItem) => (
                                                    <li key={subItem.id} className={`menu-item ${visibleSubSubmenu === subItem.id ? 'menu-item-has-children submenu-visible' : ''}`} onClick={(e) => { e.stopPropagation(); toggleSetSubmenu(subItem.id); }}>
                                                        <Link to={`/News/${subItem.rewrite}`} onClick={(e) => subItem.subCategory.length > 0 && e.preventDefault()}>
                                                            <span>{subItem.name}</span>
                                                        </Link>
                                                        {subItem.subCategory.length > 0 && (
                                                            <ul className="sub-menu subsub-menu text-muted font-small">
                                                                {subItem.subCategory.map((subSubItem) => (
                                                                    <li key={subSubItem.id} className="menu-item">
                                                                        <Link to={`/News/${subSubItem.rewrite}`}>
                                                                            {subSubItem.name}
                                                                        </Link>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        )}
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </li>
                                ))}

                                
                            </ul>

                            <div className={size < 991 ? "d-block d-lg-none" : "d-none"}>
                                <button onClick={toggleTrueFalse}>
                                    <span className="menu-icon mr-10">
                                        <span className="menu-icon-inner"></span>
                                    </span>
                                    MENU
                                </button>
                                <NavMenu isToggled={isToggled} />
                            </div>
                        </nav>
                    </div>
          
                    <div className="clearfix"></div>
                </div>
            </div>
        </>
    );
};

export default Menu;
