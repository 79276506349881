import React, { useEffect, useState } from 'react';
import axios from 'axios'; // make sure to install axios or use fetch API
import { Link, useParams } from 'react-router-dom';
import Layout from "./../components/layout/Layout";
import { format } from 'date-fns';
import DOMPurify from 'dompurify';

function SingleContent({ htmlContent }) {
    const createMarkup = (html) => {
        return { __html: DOMPurify.sanitize(html) };
    };

    return <div dangerouslySetInnerHTML={createMarkup(htmlContent)} />;
}



//function SingleContent({ htmlContent }) {
//    return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
//}

function Single() {
    const params = useParams();
    const rewrite = params.rewrite;
    const pagename = params.postname;
    const [data, setData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const cat = await axios.get(`https://api.kriptosut.com/api/v1/haberler/kategori/getir/${rewrite}`);
                console.log(cat);
                const response = await axios.get(`https://api.kriptosut.com/api/v1/haberler/haber-detay/${cat.data.reWrite}/${pagename}`);
                setData(response.data); // Set data from the first API
                console.log(pagename);
                console.log(response.data);
                const formattedDate = format(new Date(data.createDate), 'MMMM dd, yyyy');

               

            } catch (error) {
                console.error('Error fetching data: ', error);
            }
        };

        fetchData();
    }, [rewrite, pagename]);

    return (
        <>
            <Layout>
                <main className="blur-background pb-30">
                    <div className="container single-content">
                        <div className="entry-header entry-header-style-1 mb-50 pt-50">
                            <h1 className="entry-title mb-50 font-weight-900">
                                {data.title}
                            </h1>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="entry-meta align-items-center meta-2 font-small color-muted">
                                       
                                        <span className="mr-10">
                                            {" "}
                                            {data.createDate}
                                        </span>
                                      
                                    </div>
                                </div>
                                <div className="col-md-6 text-right d-none d-md-inline">
                                    <ul className="header-social-network d-inline-block list-inline mr-15">
                                        <li className="list-inline-item text-muted">
                                            <span>Share this: </span>
                                        </li>
                                        <li className="list-inline-item">
                                            <a
                                                className="social-icon fb text-xs-center"
                                                target="_blank"
                                                href="#"
                                            >
                                                <i className="elegant-icon social_facebook"></i>
                                            </a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a
                                                className="social-icon tw text-xs-center"
                                                target="_blank"
                                                href="#"
                                            >
                                                <i className="elegant-icon social_twitter "></i>
                                            </a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a
                                                className="social-icon pt text-xs-center"
                                                target="_blank"
                                                href="#"
                                            >
                                                <i className="elegant-icon social_pinterest "></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* <!--end single header--> */}
                        <figure className="image mb-30 m-auto text-center border-radius-10">
                            <img
                                className="border-radius-10"
                                src={data.coverPicture || '/assets/imgs/favicon.png'}
                                alt="post-title"
                            />
                        </figure>
                        {/* <!--figure--> */}
                        <article className="mb-50">
                            <SingleContent htmlContent={data.desc} />
                            <div className="entry-main-content dropcap wow fadeIn animated">
                                {/*<p>*/}
                                {/*    {data.canoncial}*/}
                                {/*</p>*/}
                                <hr className="wp-block-separator is-style-dots" />
                                <SingleContent htmlContent={data.content} />

                                {/*<figure className="wp-block-gallery columns-3 wp-block-image">*/}
                                {/*    <ul className="blocks-gallery-grid">*/}
                                {/*        <li className="blocks-gallery-item">*/}
                                {/*            <Link href="/#">*/}
                                {/*                <a>*/}
                                {/*                    <img*/}
                                {/*                        className="border-radius-5"*/}
                                {/*                        src="/assets/imgs/news/thumb-2.jpg"*/}
                                {/*                        alt=""*/}
                                {/*                    />*/}
                                {/*                </a>*/}
                                {/*            </Link>*/}
                                {/*        </li>*/}
                                {/*        <li className="blocks-gallery-item">*/}
                                {/*            <Link href="/#">*/}
                                {/*                <a>*/}
                                {/*                    <img*/}
                                {/*                        className="border-radius-5"*/}
                                {/*                        src="/assets/imgs/news/thumb-3.jpg"*/}
                                {/*                        alt=""*/}
                                {/*                    />*/}
                                {/*                </a>*/}
                                {/*            </Link>*/}
                                {/*        </li>*/}
                                {/*        <li className="blocks-gallery-item">*/}
                                {/*            <Link href="/#">*/}
                                {/*                <a>*/}
                                {/*                    <img*/}
                                {/*                        className="border-radius-5"*/}
                                {/*                        src="/assets/imgs/news/thumb-4.jpg"*/}
                                {/*                        alt=""*/}
                                {/*                    />*/}
                                {/*                </a>*/}
                                {/*            </Link>*/}
                                {/*        </li>*/}
                                {/*    </ul>*/}
                                {/*    <figcaption>*/}
                                {/*        {" "}*/}
                                {/*        <i className="ti-credit-card mr-5"></i>*/}
                                {/*        Image credit: Pexel{" "}*/}
                                {/*    </figcaption>*/}
                                {/*</figure>*/}
                                <hr className="section-divider" />
                             
                            </div>
                            <div className="entry-bottom mt-50 mb-30 wow fadeIn animated">
                                <div className="tags">
                                    <span>Tags: </span>
                                    {data.tag}
                                </div>
                            </div>
                            <div className="single-social-share clearfix wow fadeIn animated">
                              
                                <ul className="header-social-network d-inline-block list-inline float-md-right mt-md-0 mt-4">
                                    <li className="list-inline-item text-muted">
                                        <span>
                                            Share this:{" "}
                                        </span>
                                    </li>
                                    <li className="list-inline-item">
                                        <Link href="/#">
                                            <a
                                                className="social-icon fb text-xs-center"
                                                target="_blank"
                                            >
                                                <i className="elegant-icon social_facebook"></i>
                                            </a>
                                        </Link>
                                    </li>
                                    <li className="list-inline-item">
                                        <Link href="/#">
                                            <a
                                                className="social-icon tw text-xs-center"
                                                target="_blank"
                                            >
                                                <i className="elegant-icon social_twitter "></i>
                                            </a>
                                        </Link>
                                    </li>
                                    <li className="list-inline-item">
                                        <Link href="/#">
                                            <a
                                                className="social-icon pt text-xs-center"
                                                target="_blank"
                                            >
                                                <i className="elegant-icon social_pinterest "></i>
                                            </a>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                          
                            {/*<div className="related-posts">*/}
                            {/*    <div className="post-module-3">*/}
                            {/*        <div className="widget-header-2 position-relative mb-30">*/}
                            {/*            <h5 className="mt-5 mb-30">*/}
                            {/*                Related posts*/}
                            {/*            </h5>*/}
                            {/*        </div>*/}
                            {/*        <div className="loop-list loop-list-style-1">*/}
                            {/*            <article className="hover-up-2 transition-normal wow fadeInUp  animated">*/}
                            {/*                <div className="row mb-40 list-style-2">*/}
                            {/*                    <div className="col-md-4">*/}
                            {/*                        <div className="post-thumb position-relative border-radius-5">*/}
                            {/*                            <div*/}
                            {/*                                className="img-hover-slide border-radius-5 position-relative"*/}
                            {/*                                style={{*/}
                            {/*                                    backgroundImage:*/}
                            {/*                                        "url(assets/imgs/news/news-13.jpg)",*/}
                            {/*                                }}*/}
                            {/*                            >*/}
                            {/*                                <Link href="/single">*/}
                            {/*                                    <a className="img-link"></a>*/}
                            {/*                                </Link>*/}
                            {/*                            </div>*/}
                            {/*                            <ul className="social-share">*/}
                            {/*                                <li>*/}
                            {/*                                    <Link href="/#">*/}
                            {/*                                        <a>*/}
                            {/*                                            <i className="elegant-icon social_share"></i>*/}
                            {/*                                        </a>*/}
                            {/*                                    </Link>*/}
                            {/*                                </li>*/}
                            {/*                                <li>*/}
                            {/*                                    <Link href="/#">*/}
                            {/*                                        <a*/}
                            {/*                                            className="fb"*/}
                            {/*                                            title="Share on Facebook"*/}
                            {/*                                            target="_blank"*/}
                            {/*                                        >*/}
                            {/*                                            <i className="elegant-icon social_facebook"></i>*/}
                            {/*                                        </a>*/}
                            {/*                                    </Link>*/}
                            {/*                                </li>*/}
                            {/*                                <li>*/}
                            {/*                                    <Link href="/#">*/}
                            {/*                                        <a*/}
                            {/*                                            className="tw"*/}
                            {/*                                            target="_blank"*/}
                            {/*                                            title="Tweet now"*/}
                            {/*                                        >*/}
                            {/*                                            <i className="elegant-icon social_twitter"></i>*/}
                            {/*                                        </a>*/}
                            {/*                                    </Link>*/}
                            {/*                                </li>*/}
                            {/*                                <li>*/}
                            {/*                                    <Link href="/#">*/}
                            {/*                                        <a*/}
                            {/*                                            className="pt"*/}
                            {/*                                            target="_blank"*/}
                            {/*                                            title="Pin it"*/}
                            {/*                                        >*/}
                            {/*                                            <i className="elegant-icon social_pinterest"></i>*/}
                            {/*                                        </a>*/}
                            {/*                                    </Link>*/}
                            {/*                                </li>*/}
                            {/*                            </ul>*/}
                            {/*                        </div>*/}
                            {/*                    </div>*/}
                            {/*                    <div className="col-md-8 align-self-center">*/}
                            {/*                        <div className="post-content">*/}
                            {/*                            <div className="entry-meta meta-0 font-small mb-10">*/}
                            {/*                                <Link href="/category">*/}
                            {/*                                    <a>*/}
                            {/*                                        <span className="post-cat text-primary">*/}
                            {/*                                            Food*/}
                            {/*                                        </span>*/}
                            {/*                                    </a>*/}
                            {/*                                </Link>*/}
                            {/*                            </div>*/}
                            {/*                            <h5 className="post-title font-weight-900 mb-20">*/}
                            {/*                                <Link href="/single">*/}
                            {/*                                    <a>*/}
                            {/*                                        Helpful*/}
                            {/*                                        Tips*/}
                            {/*                                        for*/}
                            {/*                                        Working*/}
                            {/*                                        from*/}
                            {/*                                        Home*/}
                            {/*                                        as*/}
                            {/*                                        a*/}
                            {/*                                        Freelancer*/}
                            {/*                                    </a>*/}
                            {/*                                </Link>*/}
                            {/*                                <span className="post-format-icon">*/}
                            {/*                                    <i className="elegant-icon icon_star_alt"></i>*/}
                            {/*                                </span>*/}
                            {/*                            </h5>*/}
                            {/*                            <div className="entry-meta meta-1 float-left font-x-small text-uppercase">*/}
                            {/*                                <span className="post-on">*/}
                            {/*                                    7*/}
                            {/*                                    August*/}
                            {/*                                </span>*/}
                            {/*                                <span className="time-reading has-dot">*/}
                            {/*                                    11*/}
                            {/*                                    mins*/}
                            {/*                                    read*/}
                            {/*                                </span>*/}
                            {/*                                <span className="post-by has-dot">*/}
                            {/*                                    3k*/}
                            {/*                                    views*/}
                            {/*                                </span>*/}
                            {/*                            </div>*/}
                            {/*                        </div>*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*            </article>*/}
                            {/*            <article className="hover-up-2 transition-normal wow fadeInUp  animated">*/}
                            {/*                <div className="row mb-40 list-style-2">*/}
                            {/*                    <div className="col-md-4">*/}
                            {/*                        <div className="post-thumb position-relative border-radius-5">*/}
                            {/*                            <div*/}
                            {/*                                className="img-hover-slide border-radius-5 position-relative"*/}
                            {/*                                style={{*/}
                            {/*                                    backgroundImage:*/}
                            {/*                                        "url(assets/imgs/news/news-4.jpg)",*/}
                            {/*                                }}*/}
                            {/*                            >*/}
                            {/*                                <Link href="/single">*/}
                            {/*                                    <a className="img-link"></a>*/}
                            {/*                                </Link>*/}
                            {/*                            </div>*/}
                            {/*                            <ul className="social-share">*/}
                            {/*                                <li>*/}
                            {/*                                    <Link href="/#">*/}
                            {/*                                        <a>*/}
                            {/*                                            <i className="elegant-icon social_share"></i>*/}
                            {/*                                        </a>*/}
                            {/*                                    </Link>*/}
                            {/*                                </li>*/}
                            {/*                                <li>*/}
                            {/*                                    <Link href="/#">*/}
                            {/*                                        <a*/}
                            {/*                                            className="fb"*/}
                            {/*                                            title="Share on Facebook"*/}
                            {/*                                            target="_blank"*/}
                            {/*                                        >*/}
                            {/*                                            <i className="elegant-icon social_facebook"></i>*/}
                            {/*                                        </a>*/}
                            {/*                                    </Link>*/}
                            {/*                                </li>*/}
                            {/*                                <li>*/}
                            {/*                                    <Link href="/#">*/}
                            {/*                                        <a*/}
                            {/*                                            className="tw"*/}
                            {/*                                            target="_blank"*/}
                            {/*                                            title="Tweet now"*/}
                            {/*                                        >*/}
                            {/*                                            <i className="elegant-icon social_twitter"></i>*/}
                            {/*                                        </a>*/}
                            {/*                                    </Link>*/}
                            {/*                                </li>*/}
                            {/*                                <li>*/}
                            {/*                                    <Link href="/#">*/}
                            {/*                                        <a*/}
                            {/*                                            className="pt"*/}
                            {/*                                            target="_blank"*/}
                            {/*                                            title="Pin it"*/}
                            {/*                                        >*/}
                            {/*                                            <i className="elegant-icon social_pinterest"></i>*/}
                            {/*                                        </a>*/}
                            {/*                                    </Link>*/}
                            {/*                                </li>*/}
                            {/*                            </ul>*/}
                            {/*                        </div>*/}
                            {/*                    </div>*/}
                            {/*                    <div className="col-md-8 align-self-center">*/}
                            {/*                        <div className="post-content">*/}
                            {/*                            <div className="entry-meta meta-0 font-small mb-10">*/}
                            {/*                                <Link href="/category">*/}
                            {/*                                    <a>*/}
                            {/*                                        <span className="post-cat text-success">*/}
                            {/*                                            Cooking*/}
                            {/*                                        </span>*/}
                            {/*                                    </a>*/}
                            {/*                                </Link>*/}
                            {/*                            </div>*/}
                            {/*                            <h5 className="post-title font-weight-900 mb-20">*/}
                            {/*                                <Link href="/#">*/}
                            {/*                                    <a>*/}
                            {/*                                        10*/}
                            {/*                                        Easy*/}
                            {/*                                        Ways*/}
                            {/*                                        to*/}
                            {/*                                        Be*/}
                            {/*                                        Environmentally*/}
                            {/*                                        Conscious*/}
                            {/*                                        At*/}
                            {/*                                        Home*/}
                            {/*                                    </a>*/}
                            {/*                                </Link>*/}
                            {/*                            </h5>*/}
                            {/*                            <div className="entry-meta meta-1 float-left font-x-small text-uppercase">*/}
                            {/*                                <span className="post-on">*/}
                            {/*                                    27*/}
                            {/*                                    Sep*/}
                            {/*                                </span>*/}
                            {/*                                <span className="time-reading has-dot">*/}
                            {/*                                    10*/}
                            {/*                                    mins*/}
                            {/*                                    read*/}
                            {/*                                </span>*/}
                            {/*                                <span className="post-by has-dot">*/}
                            {/*                                    22k*/}
                            {/*                                    views*/}
                            {/*                                </span>*/}
                            {/*                            </div>*/}
                            {/*                        </div>*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*            </article>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/* <!--More posts--> */}
  

                        </article>
                    </div>
                    {/* <!--container--> */}
                </main>
            </Layout>
        </>
    );
}
export default Single;
